import React from 'react'
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import BackgroundImg from 'gatsby-background-image'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Layout from '../../../../../components/layout'
import SEO from '../../../../../components/seo'
import Heading from '../../../../../components/heading'
import HeroBox from '../../../../../components/herobox'
import { ButtonBase } from '@material-ui/core'
import orange from '@material-ui/core/colors/orange'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
    root: {

    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        minHeight: '60vh',
        padding: '15px',
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    wrapper: {
        minHeight: '60vh',
        padding: '25px',
        // backgroundColor: 'rgba(0,0,0,.3)',
        color: '#ffffff',
        borderColor: 'rgba(255,255,255,.5)',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    // hero: {
    //     padding: '1rem',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     flexDirection: 'column',
    //     minHeight: '80vh',
    //     textAlign: 'center',
    //     color: '#ffffff',
    // },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    heading2: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '2.5rem',
        }
    },
    heading2s: {
        // color: '#48bd98',
        // marginTop: '5rem',
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'relative',
            top: '-.1em',
            verticalAlign: 'middle',
            height: '1em',
            borderLeftColor: '#48bd98',
            borderLeftWidth: '7px',
            borderLeftStyle: 'solid',
            marginRight: '10px',
        }
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    },
    // Card
    cardRoot: {
        display: 'flex',

    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flex: '1 0 auto',
    },
    //Grid
    gridRoot: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
    },
    gridGroup2: {
        padding: '0 30px',
    },
    gridPaper: {
        padding: theme.spacing(2),
        // margin: 'auto',
        maxWidth: 600,
    },
    gridPaper2: {
        padding: theme.spacing(2),
        // margin: 'auto',
        maxWidth: 600,
    },
    gridImage: {
        width: 250,
        // height: 150,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto',
        },
    },
    gridImage2: {
        width: 350,
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto',
        },
    },
    gridImg: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    gridImg2: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    //Stepper
    stepperRoot: {
        width: '100%',
    },
    stepperButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        }
    },
    stepperButtonTxt: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
        marginBottom: '2rem',
    },
    stLabel: {
        padding: '1px',
        backgroundColor: orange[600],
        color: '#ffffff',
        borderRadius: '2px',
    },
    //Mobile Stepper
    msRoot: {
        maxWidth: 500,
        flexGrow: 1,
    },
    msGridRoot: {
        [theme.breakpoints.down('xs')]: {
            // padding: '0 8px',
            overflow: 'hidden',
        },
    },
    msGridContainer: {
        [theme.breakpoints.down('xs')]: {
            // margin: 0,
        }
    },
    msHeader: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        // paddingLeft: theme.spacing(4),
        // backgroundColor: theme.palette.background.default,
    },
    msBody: {
        // display: 'flex',
        // alignItems: 'center',
        padding: '15px'
    },
    msImg: {
        // height: 255,
        maxWidth: 1000,
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        marginBottom: '0',
    },
    msGridItem: {
        marginTop: '5rem',
        [theme.breakpoints.down('xs')]: {
            
        }
    },
    msButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    // Alert
    alertContainer: {
        // width: '100%',
        marginTop: '2rem',
        '& > * + *': {
            marginTop: theme.spacing(2),
        }
    },
    // Img
    imageContainer: {
        margin: '15px',
        '& > *': {
            borderRadius: '3px',
        }
    },
}));

function getSteps() {
    return [
        'タッチパネルで好みのワインと量を選ぶ',
        '現金を投入',
        'パネル横のラックからグラスをとる ',
        '選んだワインのボタンを長押しする '
    ];
};

const IsawaonsenStationWineServerPage = ({ data }) => {
    const classes = useStyles();
    const language = 'ja';
    const slug = 'station/isawaonsen/wine/wine-server';
    const pageTitle = '駅なかワインサーバー';
    const sectionTitle1 = '簡単! ワインサーバーの使い方';
    const sectionTitle2 = '';
    const sectionTitle3 = '';
    const notChild = false;
    const child = true;

    /** Stepper */
    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <div className={classes.gridGroup}>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step11.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    赤白ロゼ、ボトルの印象で気になるワインをチョイス
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>1-1</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step12.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    ワインを決めたら、飲みたい分量を選んでタッチパネルをタップ
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>1-2</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step13.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    選んだワインのボトル番号を覚えておいてね
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>1-3</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className={classes.gridGroup}>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step21.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    選んだワインと分量、金額を確認
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>2-1</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step22.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    間違いがなければお金を入れる
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>2-2</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className={classes.gridGroup}>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step31.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    タッチパネル左隣にあるグラス入りラック
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>3-1</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step32.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    使い捨てプラスティックグラス
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>3-2</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step33.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    グラスをとる
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>3-3</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className={classes.gridGroup}>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step41.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    ボトル番号から購入したワインを探す
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>4-1</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step42.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    選んだワインの注ぎ口にグラスをセット
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>4-2</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step43.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    入した量のボタンを、ワインが出てくるまで押す
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">4-3</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                        <div className={classes.gridRoot}>
                            <Paper className={classes.gridPaper}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ButtonBase className={classes.gridImage}>
                                            <img className={classes.gridImg} src={data.step44.childImageSharp.fluid.src} />
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography gutterButton variant="subtitle1">
                                                    &nbsp;
                                                </Typography>
                                                <Typography gutterButton variant="body2">
                                                    産地直送ワインをどうぞ
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1"><span className={classes.stLabel}>4-4</span></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                );
            default:
                return null;
        };
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const stationName = '世界でここだけ!駅なかワインサーバー';
    const descriptions = [
        '地元のワイナリー自慢のワインが、常時16種。',
        '200円から味わえる、産地直送のおいしさを、どうぞ。'
    ];

    /** MobileStepper */
    const slides1 = [
        {
            label: '',
            imgPath: data.Q1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.Q2.childImageSharp.fluid.src,
        }
    ];
    const maxSlides1 = slides1.length;
    const [activeSlides1, setActiveSlides1] = React.useState(0);
    const handleNextSlides1 = () => {
        setActiveSlides1(prevActiveSlides1 => prevActiveSlides1 + 1);
    }
    const handleBackSlides1 = () => {
        setActiveSlides1(prevActiveSlides1 => prevActiveSlides1 -1);
    }
    const slides2 = [
        {
            label: '',
            imgPath: data.L1.childImageSharp.fluid.src,
        },
        {
            label: '',
            imgPath: data.L2.childImageSharp.fluid.src,
        }
    ];
    const maxSlides2 = slides2.length;
    const [activeSlides2, setActiveSlides2] = React.useState(0);
    const handleNextSlides2 = () => {
        setActiveSlides2(prevActiveSlides2 => prevActiveSlides2 + 1);
    }
    const handleBackSlides2 = () => {
        setActiveSlides2(prevActiveSlides2 => prevActiveSlides2 -1);
    }

    const image = data.hero.childImageSharp.fluid;
    return (
        <Layout language={language} slug={slug}>
            <SEO title={pageTitle} />
            <BackgroundImg
                Tag="section"
                fluid={image}
                backgourndColor={`#48bd98`}
                // style={{
                //     minHeight: '60vh',
                //     backgroundSize: 'auto',
                // }}
            >
                <section className={classes.hero}><div className={classes.wrapper}>
                    {/* <h3><span>世界でここだけ!</span></h3> */}
                    {/* <h2><span className={classes.heading2}>駅なかワインサーバー</span></h2> */}
                    <Typography className={classes.heading2} variant="h2" content="h2">駅なかワインサーバー</Typography>
                    <div className={classes.paragraphs}>
                        {
                            descriptions.map((description, i) => (
                                <p className={classes.paragraph} key={i}><span className={classes.span}>{description}</span></p>
                            ))
                        }
                    </div>
                </div></section>
            </BackgroundImg>
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">
                    <section>
                        <Heading title="ワインサーバー行き方案内" isChild={notChild} />
                        <div className={classes.gridGroup2}>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D1.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        改札を出て右へ
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>1</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D2.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        エスカレータ下り
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>2</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D3.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        降りてUターン
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>3</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D4.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        中に入って左
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>4</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                            <div className={classes.gridRoot}>
                                <Paper className={classes.gridPaper2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ButtonBase className={classes.gridImage2}>
                                                <img className={classes.gridImg2} src={data.D5.childImageSharp.fluid.src} />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs={12} sm container>
                                            <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                    <Typography gutterButton variant="subtitle1">
                                                        &nbsp;
                                                    </Typography>
                                                    <Typography gutterButton variant="body2">
                                                        観光案内所内
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" style={{corsor: 'pointer'}}>
                                                        
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1"><span className={classes.stLabel}>5</span></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </section>
                    <section>
                        <Heading title={sectionTitle1} isChild={notChild} />
                        <div className={classes.imageContainer}><Img objectFit="contain" fluid={data.wineserver.childImageSharp.fluid} /></div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    <StepContent>
                                        <Typography>{getStepContent(index)}</Typography>
                                        <div className={classes.actionContainer}>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={classes.stepperButtonTxt}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    className={classes.stepperButton}
                                                >
                                                    {activeStep === steps.length -1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.restContainer}>
                                <Typography></Typography>
                                <Button onClick={handleReset} className={classes.stepperButton}>
                                    Restart
                                </Button>
                            </Paper>
                        )}
                        <div className={classes.alertContainer}>
                            <Alert severity="info">
                                ここのワインは、2階New Daysでも購入可能。<br />お気に入りが見つかったら、買いに行こう！
                            </Alert>
                        </div>
                    </section>
                    <section className={classes.msGridRoot}>
                        <Grid container spacing={2} className={classes.msGridContainer}>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>さらに！</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>アンケートに答えて、かっこいい手ぬぐいをget！ </p>
                                        <p>案内所受付カウンターで、「アンケート」と言って英語のアンケート用紙をもらう。</p>
                                        <p>答えたアンケートと引き替えに、このクールな手ぬぐいが手に入る。</p>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides1[activeSlides1].imgPath}
                                        alt={slides1[activeSlides1].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides1}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides1}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides1} disabled={activeSlides1 === maxSlides1 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides1} disabled={activeSlides1 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.msGridItem}>
                                <div clasName={classes.msRoot}>
                                    <Paper square elevation={0} className={classes.msHeader}>
                                        <Typography><h2><span className={classes.heading2s}>ここはぶどうの生産日本一の街</span></h2></Typography>
                                    </Paper>
                                    <div className={classes.msBody}>
                                        <p>世界が認める、おいしいワインが生まれる場所。</p>
                                        <p>産地ならではの充実の品揃え。</p>
                                        <Button
                                            href="/station/isawaonsen/wine/winery/#buyWine"
                                            variant="contained"
                                            endIcon={<KeyboardArrowRight />}
                                            className={classes.msButton}
                                        >ワインを買う</Button>
                                        <Button
                                            href="/station/isawaonsen/wine/winery/#wineryInfo"
                                            variant="contained"
                                            endIcon={<KeyboardArrowRight />}
                                            className={classes.msButton}
                                        >もっと飲む</Button>
                                    </div>
                                    <img 
                                        className={classes.msImg}
                                        src={slides2[activeSlides2].imgPath}
                                        alt={slides2[activeSlides2].label}
                                    />
                                    <MobileStepper
                                        steps={maxSlides2}
                                        position="static"
                                        variant="dots"
                                        activeStep={activeSlides2}
                                        nextButton={
                                            <Button size="small" onClick={handleNextSlides2} disabled={activeSlides2 === maxSlides1 - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={handleBackSlides2} disabled={activeSlides2 === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </section>
                </Box>
            </Container>
            {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
        </Layout>
    )
};

export const query = graphql`
  {
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wineserver:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/wine-server.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D0:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/0.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D3:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D4:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D5:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/direction/5.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step11:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step1/wine-server-touchpanel-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step12:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step1/wine-server-touchpanel-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step13:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step1/wine-server-touchpanel-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step21:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step2/wine-server-payment-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step22:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step2/wine-server-payment-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step31:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step3/wine-server-glass-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step32:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step3/wine-server-glass-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step33:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step3/wine-server-glass-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step41:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step4/wine-server-pour-1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step42:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step4/wine-server-pour-2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step43:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step4/wine-server-pour-3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    step44:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/instructions/step4/wine-server-pour-4.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Q1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/questionnaire/hand-towel.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Q2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/questionnaire/questionnaire.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    L1:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/link/grapes.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    L2:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/wine/wine-server/link/wine-glasses.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IsawaonsenStationWineServerPage